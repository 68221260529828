
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ValidationObserver } from 'vee-validate';
import FormInput from '../components/FormInput.vue';
import { RequestPasswordReset } from '@/models/auth/request-password-reset.class';
import Spinner from '@/components/TheSpinner.vue';

const authModule = namespace('auth');

@Component({
  components: { ValidationObserver, FormInput, Spinner },
})
export default class PasswordResetRequest extends Vue {
  requestPassword = new RequestPasswordReset();

  @authModule.Action('requestPasswordReset')
  requestPasswordReset!: (requestPassword: RequestPasswordReset) => Promise<boolean | string>;

  requestEmailSent = false;
  errorTranslationKey = '';
  loading = false;

  $refs!: Vue['$refs'] & {
    observer: InstanceType<typeof ValidationObserver>;
  };

  async onSubmitForm() {
    this.loading = true;
    this.errorTranslationKey = '';

    const response = await this.requestPasswordReset(this.requestPassword);
    this.loading = false;

    if (typeof response === 'string') {
      this.errorTranslationKey = response;
      this.resetForm();
    } else if (response) {
      this.requestEmailSent = true;
    }
  }
  resetForm() {
    this.requestPassword = new RequestPasswordReset();
    this.$refs.observer.reset();
  }
}
