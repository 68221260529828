
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { InputOptions } from '@/enums/input-options.enum';
import { SelectOption } from '@/models/shared/select-option.interface';

@Component({
  components: {
    ValidationProvider,
  },
})
export default class FormInput extends Vue {
  @Prop({
    type: [String, Number, Boolean],
    required: true,
  })
  value!: string;

  @Prop({
    type: String,
    required: false,
  })
  formGroupClass!: string;

  @Prop({
    type: String,
    required: true,
  })
  inputId!: string;

  @Prop({
    type: String,
    required: true,
  })
  name!: string;

  @Prop({
    type: String,
    required: false,
  })
  placeholder!: string;

  @Prop({
    type: String,
    required: false,
  })
  minDate!: string;

  @Prop({
    type: String,
    default: 'text',
  })
  type!: string;

  @Prop({
    type: Array,
  })
  validationRules!: string[];

  @Prop({
    type: String,
    default: 'Input',
  })
  inputType!: InputOptions;

  @Prop({
    type: Array,
  })
  options!: SelectOption[];

  inputOptions = InputOptions;

  get rules() {
    if (this.validationRules && this.validationRules.length) {
      return this.validationRules.reduce((accumulator, currentValue) => {
        return `${accumulator}|${currentValue}`;
      });
    }
    return '';
  }

  @Emit('update:value')
  onInputChanged(currentValue: string) {
    return currentValue;
  }
}
